@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  direction: rtl;
  font-family: "Alexandria", sans-serif;
}

/* login page */
.login-page {
  @apply w-full h-screen flex items-center justify-center;
  background-color: #f2f2f2;
}

.login-form {
  @apply w-full max-w-md p-8 bg-white rounded-lg shadow-md;
}

/* input placeholder text */
::placeholder {
  @apply text-gray-500 text-[13px];
}
input{
  @apply w-full px-3 py-2 border border-gray-200 rounded-lg  focus:outline-none;
}
/* title */
.title {
  @apply text-xl text-gray-800 font-medium;
}
.anticon svg {
  @apply  hidden
}
